.lg-icon {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'lg' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.lg-container {
    font-family: system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

.lg-next,
.lg-prev {
    background-color: rgba(0, 0, 0, 0.45);
    border-radius: 2px;
    color: #999;
    cursor: pointer;
    display: block;
    font-size: 22px;
    margin-top: -10px;
    padding: 8px 10px 9px;
    position: absolute;
    top: 50%;
    z-index: 1084;
    outline: none;
    border: none;
}

.lg-next.disabled,
.lg-prev.disabled {
    opacity: 0 !important;
    cursor: default;
}

.lg-next:hover:not(.disabled),
.lg-prev:hover:not(.disabled) {
    color: #fff;
}

.lg-single-item .lg-next, .lg-single-item
.lg-prev {
    display: none;
}

.lg-next {
    right: 20px;
}

.lg-next:before {
    content: '\e095';
}

.lg-prev {
    left: 20px;
}

.lg-prev:after {
    content: '\e094';
}

@-webkit-keyframes lg-right-end {
    0% {
        left: 0;
    }
    50% {
        left: -30px;
    }
    100% {
        left: 0;
    }
}

@-moz-keyframes lg-right-end {
    0% {
        left: 0;
    }
    50% {
        left: -30px;
    }
    100% {
        left: 0;
    }
}

@-ms-keyframes lg-right-end {
    0% {
        left: 0;
    }
    50% {
        left: -30px;
    }
    100% {
        left: 0;
    }
}

@keyframes lg-right-end {
    0% {
        left: 0;
    }
    50% {
        left: -30px;
    }
    100% {
        left: 0;
    }
}

@-webkit-keyframes lg-left-end {
    0% {
        left: 0;
    }
    50% {
        left: 30px;
    }
    100% {
        left: 0;
    }
}

@-moz-keyframes lg-left-end {
    0% {
        left: 0;
    }
    50% {
        left: 30px;
    }
    100% {
        left: 0;
    }
}

@-ms-keyframes lg-left-end {
    0% {
        left: 0;
    }
    50% {
        left: 30px;
    }
    100% {
        left: 0;
    }
}

@keyframes lg-left-end {
    0% {
        left: 0;
    }
    50% {
        left: 30px;
    }
    100% {
        left: 0;
    }
}

.lg-outer.lg-right-end .lg-object {
    -webkit-animation: lg-right-end 0.3s;
    -o-animation: lg-right-end 0.3s;
    animation: lg-right-end 0.3s;
    position: relative;
}

.lg-outer.lg-left-end .lg-object {
    -webkit-animation: lg-left-end 0.3s;
    -o-animation: lg-left-end 0.3s;
    animation: lg-left-end 0.3s;
    position: relative;
}

.lg-toolbar {
    z-index: 1082;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.lg-media-overlap .lg-toolbar {
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4));
}

.lg-toolbar .lg-icon {
    color: #999;
    cursor: pointer;
    float: right;
    font-size: 24px;
    height: 47px;
    line-height: 27px;
    padding: 10px 0;
    text-align: center;
    width: 50px;
    text-decoration: none !important;
    outline: medium none;
    will-change: color;
    -webkit-transition: color 0.2s linear;
    -o-transition: color 0.2s linear;
    transition: color 0.2s linear;
    background: none;
    border: none;
    box-shadow: none;
}

.lg-toolbar .lg-icon.lg-icon-18 {
    font-size: 18px;
}

.lg-toolbar .lg-icon:hover {
    color: #fff;
}

.lg-toolbar .lg-close:after {
    content: '\e070';
}

.lg-toolbar .lg-maximize {
    font-size: 22px;
}

.lg-toolbar .lg-maximize:after {
    content: '\e90a';
}

.lg-toolbar .lg-download:after {
    content: '\e0f2';
}

.lg-sub-html {
    color: #eee;
    font-size: 16px;
    padding: 10px 40px;
    text-align: center;
    z-index: 1080;
    opacity: 0;
    -webkit-transition: opacity 0.2s ease-out 0s;
    -o-transition: opacity 0.2s ease-out 0s;
    transition: opacity 0.2s ease-out 0s;
}

.lg-sub-html h4 {
    margin: 0;
    font-size: 13px;
    font-weight: bold;
}

.lg-sub-html p {
    font-size: 12px;
    margin: 5px 0 0;
}

.lg-sub-html a {
    color: inherit;
}

.lg-sub-html a:hover {
    text-decoration: underline;
}

.lg-media-overlap .lg-sub-html {
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
}

.lg-item .lg-sub-html {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
}

.lg-error-msg {
    font-size: 14px;
    color: #999;
}

.lg-counter {
    color: #999;
    display: inline-block;
    font-size: 16px;
    padding-left: 20px;
    padding-top: 12px;
    height: 47px;
    vertical-align: middle;
}

.lg-closing .lg-toolbar,
.lg-closing .lg-prev,
.lg-closing .lg-next,
.lg-closing .lg-sub-html {
    opacity: 0;
    -webkit-transition: -webkit-transform 0.08 cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.08 cubic-bezier(0, 0, 0.25, 1) 0s, color 0.08 linear;
    -moz-transition: -moz-transform 0.08 cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.08 cubic-bezier(0, 0, 0.25, 1) 0s, color 0.08 linear;
    -o-transition: -o-transform 0.08 cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.08 cubic-bezier(0, 0, 0.25, 1) 0s, color 0.08 linear;
    transition: transform 0.08 cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.08 cubic-bezier(0, 0, 0.25, 1) 0s, color 0.08 linear;
}

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item:not(.lg-zoomable) .lg-img-wrap,
body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item:not(.lg-zoomable) .lg-video-cont {
    opacity: 0;
    -moz-transform: scale3d(0.5, 0.5, 0.5);
    -o-transform: scale3d(0.5, 0.5, 0.5);
    -ms-transform: scale3d(0.5, 0.5, 0.5);
    -webkit-transform: scale3d(0.5, 0.5, 0.5);
    transform: scale3d(0.5, 0.5, 0.5);
    will-change: transform, opacity;
    -webkit-transition: -webkit-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
    -moz-transition: -moz-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
    -o-transition: -o-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
    transition: transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important;
}

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item:not(.lg-zoomable).lg-complete .lg-img-wrap,
body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item:not(.lg-zoomable).lg-complete .lg-video-cont {
    opacity: 1;
    -moz-transform: scale3d(1, 1, 1);
    -o-transform: scale3d(1, 1, 1);
    -ms-transform: scale3d(1, 1, 1);
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
}

.lg-icon:focus-visible {
    color: #fff;
    border-radius: 3px;
    outline: 1px dashed rgba(255, 255, 255, 0.6);
}

.lg-toolbar .lg-icon:focus-visible {
    border-radius: 8px;
    outline-offset: -5px;
}

.lg-group:after {
    content: '';
    display: table;
    clear: both;
}

.lg-container {
    display: none;
    outline: none;
}

.lg-container.lg-show {
    display: block;
}

.lg-on {
    scroll-behavior: unset;
}

.lg-overlay-open {
    overflow: hidden;
}

.lg-toolbar,
.lg-prev,
.lg-next,
.lg-pager-outer,
.lg-hide-sub-html .lg-sub-html {
    opacity: 0;
    will-change: transform, opacity;
    -webkit-transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
    -moz-transition: -moz-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
    -o-transition: -o-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
    transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
}

.lg-show-in .lg-toolbar,
.lg-show-in .lg-prev,
.lg-show-in .lg-next,
.lg-show-in .lg-pager-outer {
    opacity: 1;
}

.lg-show-in.lg-hide-sub-html .lg-sub-html {
    opacity: 1;
}

.lg-show-in .lg-hide-items .lg-prev {
    opacity: 0;
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
}

.lg-show-in .lg-hide-items .lg-next {
    opacity: 0;
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
}

.lg-show-in .lg-hide-items .lg-toolbar {
    opacity: 0;
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
}

.lg-show-in .lg-hide-items.lg-hide-sub-html .lg-sub-html {
    opacity: 0;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
}

.lg-outer {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    text-align: left;
    opacity: 0.001;
    outline: none;
    will-change: auto;
    overflow: hidden;
    -webkit-transition: opacity 0.15s ease 0s;
    -o-transition: opacity 0.15s ease 0s;
    transition: opacity 0.15s ease 0s;
}

.lg-outer * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.lg-outer.lg-zoom-from-image {
    opacity: 1;
}

.lg-outer.lg-visible {
    opacity: 1;
}

.lg-outer.lg-css3 .lg-item:not(.lg-start-end-progress).lg-prev-slide, .lg-outer.lg-css3 .lg-item:not(.lg-start-end-progress).lg-next-slide, .lg-outer.lg-css3 .lg-item:not(.lg-start-end-progress).lg-current {
    -webkit-transition-duration: inherit !important;
    transition-duration: inherit !important;
    -webkit-transition-timing-function: inherit !important;
    transition-timing-function: inherit !important;
}

.lg-outer.lg-css3.lg-dragging .lg-item.lg-prev-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-next-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-current {
    -webkit-transition-duration: 0s !important;
    transition-duration: 0s !important;
    opacity: 1;
}

.lg-outer.lg-grab img.lg-object {
    cursor: -webkit-grab;
    cursor: -moz-grab;
    cursor: -o-grab;
    cursor: -ms-grab;
    cursor: grab;
}

.lg-outer.lg-grabbing img.lg-object {
    cursor: move;
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing;
    cursor: -o-grabbing;
    cursor: -ms-grabbing;
    cursor: grabbing;
}

.lg-outer .lg-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.lg-outer .lg-inner {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    -webkit-transition: opacity 0s;
    -o-transition: opacity 0s;
    transition: opacity 0s;
    white-space: nowrap;
}

.lg-outer .lg-item {
    display: none !important;
}

.lg-outer .lg-item:not(.lg-start-end-progress) {
    background: url("../images/loading.gif") no-repeat scroll center center transparent;
}

.lg-outer.lg-css3 .lg-prev-slide,
.lg-outer.lg-css3 .lg-current,
.lg-outer.lg-css3 .lg-next-slide {
    display: inline-block !important;
}

.lg-outer.lg-css .lg-current {
    display: inline-block !important;
}

.lg-outer .lg-item,
.lg-outer .lg-img-wrap {
    display: inline-block;
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
}

.lg-outer .lg-item:before,
.lg-outer .lg-img-wrap:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.lg-outer .lg-img-wrap {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    white-space: nowrap;
    font-size: 0;
}

.lg-outer .lg-item.lg-complete {
    background-image: none;
}

.lg-outer .lg-item.lg-current {
    z-index: 1060;
}

.lg-outer .lg-object {
    display: inline-block;
    vertical-align: middle;
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    position: relative;
}

.lg-outer .lg-empty-html.lg-sub-html,
.lg-outer .lg-empty-html .lg-sub-html {
    display: none;
}

.lg-outer.lg-hide-download .lg-download {
    opacity: 0.75;
    pointer-events: none;
}

.lg-outer .lg-first-slide .lg-dummy-img {
    position: absolute;
    top: 50%;
    left: 50%;
}

.lg-outer.lg-components-open:not(.lg-zoomed) .lg-components {
    -webkit-transform: translate3d(0, 0%, 0);
    transform: translate3d(0, 0%, 0);
    opacity: 1;
}

.lg-outer.lg-components-open:not(.lg-zoomed) .lg-sub-html {
    opacity: 1;
    transition: opacity 0.2s ease-out 0.15s;
}

.lg-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1040;
    background-color: #000;
    opacity: 0;
    will-change: auto;
    -webkit-transition: opacity 333ms ease-in 0s;
    -o-transition: opacity 333ms ease-in 0s;
    transition: opacity 333ms ease-in 0s;
}

.lg-backdrop.in {
    opacity: 1;
}

.lg-css3.lg-no-trans .lg-prev-slide,
.lg-css3.lg-no-trans .lg-next-slide,
.lg-css3.lg-no-trans .lg-current {
    -webkit-transition: none 0s ease 0s !important;
    -moz-transition: none 0s ease 0s !important;
    -o-transition: none 0s ease 0s !important;
    transition: none 0s ease 0s !important;
}

.lg-css3.lg-use-css3 .lg-item {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
}

.lg-css3.lg-fade .lg-item {
    opacity: 0;
}

.lg-css3.lg-fade .lg-item.lg-current {
    opacity: 1;
}

.lg-css3.lg-fade .lg-item.lg-prev-slide, .lg-css3.lg-fade .lg-item.lg-next-slide, .lg-css3.lg-fade .lg-item.lg-current {
    -webkit-transition: opacity 0.1s ease 0s;
    -moz-transition: opacity 0.1s ease 0s;
    -o-transition: opacity 0.1s ease 0s;
    transition: opacity 0.1s ease 0s;
}

.lg-css3.lg-use-css3 .lg-item.lg-start-progress {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
    -o-transition: -o-transform 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
    transition: transform 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
}

.lg-css3.lg-use-css3 .lg-item.lg-start-end-progress {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s;
}

.lg-css3.lg-slide.lg-use-css3 .lg-item {
    opacity: 0;
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
    -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
    transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}

.lg-container {
    display: none;
}

.lg-container.lg-show {
    display: block;
}

.lg-container.lg-dragging-vertical .lg-backdrop {
    -webkit-transition-duration: 0s !important;
    transition-duration: 0s !important;
}

.lg-container.lg-dragging-vertical .lg-css3 .lg-item.lg-current {
    -webkit-transition-duration: 0s !important;
    transition-duration: 0s !important;
    opacity: 1;
}

.lg-inline .lg-backdrop,
.lg-inline .lg-outer {
    position: absolute;
}

.lg-inline .lg-backdrop {
    z-index: 1;
}

.lg-inline .lg-outer {
    z-index: 2;
}

.lg-inline .lg-maximize:after {
    content: '\e909';
}

.lg-components {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    will-change: transform;
    -webkit-transition: -webkit-transform 0.35s ease-out 0s;
    -moz-transition: -moz-transform 0.35s ease-out 0s;
    -o-transition: -o-transform 0.35s ease-out 0s;
    transition: transform 0.35s ease-out 0s;
    z-index: 1080;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
}
